@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/animate.css/animate.css");
@import url("https://cdn.kernvalley.us/css/core-css/animations.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/accordion.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("./layout.css");

:root {
	font-size: 18px;
}

@supports (width: clamp(1px, 2em, 1rem)) {
	:root {
		font-size: clamp(16px, 1.3vmax, 24px);
	}
}

img.avatar {
	vertical-align: middle;
	margin-left: 1.2em;
}
